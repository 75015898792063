@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-image: url("./assets/background.png");
  background-size: cover;
  color: #f9f9f9;
  background-repeat: repeat;
  background-attachment: scroll;
}

.pixel-font {
  /* font-family: "VT323", monospace; */
  font-family: "Press Start 2P", cursive;
}

.pixel-border {
  box-shadow: -8px 0 0 0 black, 8px 0 0 0 black, 0 -8px 0 0 black,
    0 8px 0 0 black;
}

.small-pixel-border {
  box-shadow: -4px 0 0 0 black, 4px 0 0 0 black, 0 -4px 0 0 black,
    0 4px 0 0 black;
}
