.home-login {
  position: fixed;
  z-index: 3;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 90%;
  padding: 30px 30px;
  border-radius: 22px;
  background: #df7007c;
  text-align: center;
}

@media (width >= 450px) {
  .home-login {
    width: 450px;
  }
}

@media (width <= 450px) {
  font-size: 14px;
}
